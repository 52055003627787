/* Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Body and HTML */
body,
html {
  height: 100%;
}

body {
  background-color: #efefef;
}

/* Navigation Bar */
.navbar {
  position: fixed;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  z-index: 1000;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  color: white;
  font-size: 1.5em;
  text-decoration: none;
}

.navbar-menu {
  list-style: none;
  display: flex;
}

.navbar-menu li {
  margin-left: 20px;
}

.navbar-menu a {
  color: white;
  text-decoration: none;
  font-size: 1em;
  transition: color 0.3s ease;
}

.navbar-menu a:hover {
  color: #ffcccb;
}

/* Mobile Menu Toggle */
.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.navbar-toggle .bar {
  height: 3px;
  width: 25px;
  background-color: white;
  margin: 4px 0;
  transition: all 0.3s ease;
}

.content {
  position: relative;
  z-index: 1;
  text-align: center;
  color: white;
  top: 50%;
  transform: translateY(-50%);
}

.names {
  font-size: 2em;
  margin-bottom: 10px;
}

h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.countdown {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.countdown-item {
  margin: 0 10px;
  text-align: center;
}

.countdown-item span {
  font-size: 2em;
  display: block;
}

.countdown-item p {
  font-size: 1em;
}


/* Additional Sections */
section {
  padding: 100px 20px;
  text-align: center;
}

.section-content {
  max-width: 800px;
  margin: 0 auto;
  color: #333;
}

section#our-story {
  background-color: #f9f9f9;
}

section#rsvp {
  background-color: #e9e9e9;
}

li.active {
  font-weight: bold;
}

#calendar-options {
  display: none;
  position: absolute;
  top: 34em;
  left: 35%;
  z-index: 1000;
  background-color: white;
  padding: 1em;
  box-shadow: 1px 2px 10px #1c1c1c;
  border-radius: 10px;
  width: 22em;
}

#calendar-options.active {
  display: grid;
}

div#calendar-options a {
  text-decoration: none;
}

a.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorPrimary.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-colorPrimary.css-1a6s2m4 {
  font-family: 'Lato';
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar-menu {
    display: none;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 50px;
    right: 0;
    width: 200px;
  }

  .navbar-menu li {
    margin: 15px 0;
    text-align: center;
  }

  .navbar-toggle {
    display: flex;
  }

  .navbar-menu.active {
    display: flex;
  }

  ul.MuiList-root.MuiList-padding.MuiMenu-list.css-99wn1p-MuiList-root-MuiMenu-list {
    height: 24em;
  }

  ul.MuiList-root.MuiList-padding.MuiMenu-list.css-1c1ttle {
    height: 24em;
  }

  li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-1h4bzp1-MuiButtonBase-root-MuiMenuItem-root {
    padding-top: 37px;
    border-bottom: 1px solid #efefef;
  }

  li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-ljk74r {
    padding-top: 37px;
    border-bottom: 1px solid #efefef;
  }

  a.MuiTypography-root.MuiTypography-body1.css-751u76-MuiTypography-root {
    font-family: 'Dancing Script';
    font-size: 25px;
  }

  a.MuiTypography-root.MuiTypography-body1.css-1fiq1v4 {
    font-family: 'Dancing Script';
    font-size: 25px;
  }
}