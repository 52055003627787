/* Video Background */
.video-background {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background: #000000a8;
    width: 100vw;
}

#background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 135vw;
    height: 100vh;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
}