.slick-prev:before,
.slick-next:before {
  font-size: 32px !important;
  color: #97a8ae !important;
}

.slick-prev {
  left: -40px;
}

.slick-next {
  right: -30px;
}