@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
body {
  margin: 0;
  font-family: 'Dancing Script';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span,p{
  font-family: "Lato", sans-serif !important;
  font-weight: 300;
  font-style: normal;
}

.light-lato-font{
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
